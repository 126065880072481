import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerArtsPrograms from '../components/BannerArtsPrograms';
import JSONData from '../content/artgroup.json';

const ArtsGroups = (props) => (
  <Layout>
    <Helmet>
      <title>Arts Programs - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="We provide playbills for the most respected arts organizations in Utah. Our publications allow your business to connect with a sophisticated, captive audience that returns and grows every season."
      />
    </Helmet>

    <BannerArtsPrograms />

    <div id="main">
      <section id="one">
        <div className="inner">
          <h3>Demographic Highlights</h3>
          <div className="grid-wrapper demographics">
            <section className="col-3 box">
              <h4>Readership</h4>
              <i className="icon fa-book demo-icon"></i>
              <p>
                Our performing arts playbills reach an audience of approximately{' '}
                <strong>1,000,000+ readers annually</strong>.
              </p>
            </section>
            <section className="col-3 box">
              <h4>Affluent</h4>
              <i className="icon fa-user-md demo-icon"></i>
              <p>
                <strong>Over 50% of patrons </strong>
                are employed in either professional or managerial positions.
              </p>
            </section>
            <section className="col-3 box">
              <h4>Higher Education</h4>
              <i className="icon fa-flask demo-icon"></i>
              <p>
                On average, <strong>75% of audience members</strong> have a
                college education or greater
              </p>
            </section>
            <section className="col-3 box">
              <h4>Disposable Income</h4>
              <i className="icon fa-dollar demo-icon"></i>
              <p>
                Audience members have an average household{' '}
                <strong>income of $135,000+</strong>.
              </p>
            </section>
          </div>
        </div>
        <div className="inner">
          <header className="major">
            <h2>Step into the Spotlight!</h2>
          </header>
          <p>
            We provide playbills for the most respected arts organizations in
            Utah. Our publications allow your business to connect with a
            sophisticated, captive audience that returns and grows every season.
            Luxury brand advertising and other unique packages are available to
            maximize your budget across multiple publications, giving you the
            greatest reach for your dollar.
          </p>
          <div className="col-6 flexy-buttons">
            <ul className="actions">
              <li>
                <a href="#balletwest" className="button small scrolly arts">
                  Ballet West
                </a>
              </li>
              <li>
                <a href="#broadwayeccles" className="button small scrolly arts">
                  Broadway at the Eccles
                </a>
              </li>
              <li>
                <a href="#broadwayboise" className="button small scrolly arts">
                  Broadway in Boise
                </a>
              </li>
              <li>
                <a href="#deervalley" className="button small scrolly arts">
                  Deer Valley® Music Festival
                </a>
              </li>
              <li>
                <a href="#grandtheatre" className="button small scrolly arts">
                  Grand Theatre
                </a>
              </li>
              <li>
                <a href="#kurtbestor" className="button small scrolly arts">
                  Kurt Bestor
                </a>
              </li>
              <li>
                <a href="#odyssey" className="button small scrolly arts">
                  Odyssey Dance Theatre
                </a>
              </li>
              <li>
                <a href="#onstageogden" className="button small scrolly arts">
                  Onstage Ogden
                </a>
              </li>
              <li>
                <a href="#ptc" className="button small scrolly arts">
                  Pioneer theatre Company
                </a>
              </li>
              <li>
                <a href="#slac" className="button small scrolly arts">
                  Salt Lake Acting Company
                </a>
              </li>
              <li>
                <a href="#tuacahn" className="button small scrolly arts">
                  Tuacahn Amphitheatre
                </a>
              </li>
              <li>
                <a href="#uaf" className="button small scrolly arts">
                  Utah Arts Festival
                </a>
              </li>
              <li>
                <a href="#ufo" className="button small scrolly arts">
                  Utah Festival Opera
                </a>
              </li>
              <li>
                <a href="#opera" className="button small scrolly arts">
                  Utah Opera
                </a>
              </li>
              <li>
                <a href="#utahpresents" className="button small scrolly arts">
                  UtahPresents
                </a>
              </li>

              <li>
                <a href="#shakespeare" className="button small scrolly arts">
                  Utah Shakespeare Festival
                </a>
              </li>
              <li>
                <a href="#utahsymphony" className="button small scrolly arts">
                  Utah Symphony
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        {JSONData.artgroups.map((data, index) => {
          return (
            <section id={data.anchor} key={index}>
              <Link to={data.link} className="image">
                <img src={data.image} alt="" />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>{data.title}</h3>
                  </header>
                  <p>{data.desc}</p>
                  <ul className="actions">
                    <li>
                      <Link to={data.link} className="button">
                        Learn more
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          );
        })}
      </section>
    </div>
  </Layout>
);

export default ArtsGroups;
