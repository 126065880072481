import React from 'react';

const BannerArtsPrograms = (props) => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Arts Programs</h1>
      </header>
      <div className="content">
        <p>
          Mills Publishing Inc. handles the production of 17 distinct arts
          programs, each one more culturally relevant than the last. Take a
          moment to explore advertising opportunities in each of them—you’re
          sure to find something that will elevate your brand!
        </p>
        <ul className="actions">
          <li>
            <a href="/advertising-specifications" className="button special">
              Arts Programs Ad Specs
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default BannerArtsPrograms;
